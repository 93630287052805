/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';

// import pdf1 from 'assests/Pdf/Resources/annual-report.pdf';
// import pdf2 from 'assests/Pdf/Resources/CalendarfromDiary.pdf';
// import pdf3 from 'assests/Pdf/Resources/Staff-details-credentia.pdf';
// import pdf4 from 'assests/Pdf/Resources/books_declaration.pdf';
// import pdf5 from 'assests/Pdf/Resources/fee_declaration.pdf';
// import pdf6 from 'assests/Pdf/Resources/list_of_text_book.pdf';


import Container from 'components/Container';
import Main from 'layouts/Main';



const Resources = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const pdf1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/annual-report.pdf`;
  const pdf2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/CalendarfromDiary.pdf`;
  const pdf3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/Staff-details-credentia.pdf`;
  const pdf4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/books_declaration.pdf`;
  const pdf5 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/fee_declaration.pdf`;
  const pdf6 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/Resources/list_of_text_book.pdf`;
  

  const mock = [
    {
      color: colors.lightBlue[500],
      title: 'Annual Report',
      subtitle: '',
      href: pdf1,
    },
    {
      color: colors.purple[500],
      title: 'Almanac',
      subtitle: '',
      href: pdf2,
    
    },
    {
      color: colors.yellow[500],
      title: 'Staff Details',
      subtitle: '',
      href: pdf3,
  
    },
    {
      color: colors.indigo[500],
      title: 'Text book List',
      subtitle: '',
      href: pdf6,
  
    },
    {
      color: colors.red[500],
      title: 'Declaration',
      subtitle: '',
      href: pdf4,
      
     
    },
    {
      color: colors.green[500],
      title: 'Fee Declaration',
      subtitle: '',
      href: pdf5,
     
    },
  ];


  return (
    <Main>
    <Box bgcolor={'alternate.main'}>
        <Container>
            <Grid>
                <Typography variant='h4' fontWeight={700} align='center'>
                    Resources
                </Typography>

            </Grid>
        </Container>
      <Container>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <Box component={Card} padding={4} width={1} height={1}>
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        href={item.href} target="_blank"
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Learn More
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
    </Main>
  );
};

export default Resources;
